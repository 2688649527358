import React from 'react';

import SignIn from './SignIn';

export default function LoginScreen() {
  return (
    <div>
      <h1>Please Login Below!</h1>
      <SignIn />
    </div>
  );
}
